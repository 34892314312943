<template>
  <div class="content-fullheight notfound"></div>
</template>


<script>
export default {
  name: "NotFound",
  created() {
    this.$router.replace({ name: "Home" });
  },
};
</script>